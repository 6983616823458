import React from "react";
import Education from "./education";
import Work from "./works";
import "./styles/homepage_bottom.css"; // Create a CSS file for styling

const EducationAndWork = () => {
    return (
        <div className="education-and-work-container">
            <div className="education-container">
                <Education />
            </div>
            <div className="work-container">
                <Work />
            </div>
        </div>
    );
};

export default EducationAndWork;
