const SEO = [
	{
		page: "home",
		description:
			"Welcome to my portfolio website! Feel free to look around to learn more about me and my projects. You can also connect with me on LinkedIn or send me an email. I currently have an internship for Summer 2024 but I'm still looking for Co-Op opportunities for Fall 2024!",
		keywords: ["Yasir", "Yasir Y", "Yasir Yilmazcoban"],
	},

	{
		page: "about",
		description:
			"Hi there! My name is Yasir Yilmazcoban. I'm a Turkish-American junior computer science student at Texas A&M University. I'm interested in software development and engineering. I'm currently working on a roommate companion app called Sp/it and continuing my summer internship at R1649 remotely. I'll also be interning as a SWE at Meta this summer! I'm looking for SWE and Project Management Co-Ops for Fall 2024!\nI have experience working with Python, C++, Java, JavaScript, SQL, React/React Native, Express.js, Firebase, Cloud Databases, and more (my full skill list is on my resume). I'm also learning project management through my role in Sp/it, where I use Jira to plan and assign sprints to promote agile development. I'm also learning about cloud computing, web development, and blockchain development through my internship at R1649. I'm a quick learner and am always looking to learn new skills and technologies!\nI'm based in Texas but I'm open to relocation and remote work. I hold permanent residency status in the U.S. and am authorized to work in the U.S. without sponsorship. Feel free to reach out to me if you have any questions or opportunities!",
		keywords: ["Yasir", "Yasir Y", "Yasir Yilmazcoban"],
	},

	{
		page: "projects",
		description:
			"I've been trying to learn new technologies by doing projects since middle school.I've dabbled with object - oriented programming, web development, mobile development, game design, cloud databases, and more. Here are some of my favorite projects I've worked on. If you'd like to see some of the other projects I've worked on, feel free to check out my GitHub Repos or reach out to me!",
		keywords: ["Yasir", "Yasir Y", "Yasir Yilmazcoban"],
	},

	{
		page: "contact",
		description:
			"Thank you for your interest in getting in touch with me. I welcome your feedback, questions, and internship opportunities :). Please feel free to contact me on any of the platforms below. I make an effort to respond to all messages within 24 hours, even during busy periods. Thanks again for your interest, and I look forward to hearing from you!",
		keywords: ["Yasir", "Yasir Y", "Yasir Yilmazcoban"],
	},
];

export default SEO;
