import React from "react";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";
import "./styles/education.css";

const Educations = () => {
    const educationsData = [
        {
            school: "Texas A&M University",
            degree: "B.S. in Computer Science",
            duration: "August 2022 - May 2026",
            logoSrc: "./tamu_logo.png",
        },
        {
            school: "Texas A&M University",
            degree: "M.S. in Finance",
            duration: "January 2024 - May 2027",
            logoSrc: "./tamu_logo.png",
        },
    ];

    return (
        <div className="education">
            <Card
                icon={faGraduationCap}
                title="Education"
                body={
                    <div className="education-body">
                        {educationsData.map((education, index) => (
                            <div className="education" key={index}>

                                <img
                                    src={education.logoSrc}
                                    alt={education.school}
                                    className="education-image"
                                />
                                <div className="education-title">{education.school}</div>
                            <div className="education-secondary">
                                <div className="education-subtitle">{education.degree}</div>
                                <div className="education-duration">{education.duration}</div>
                            </div>
                            </div>
                        ))}
                    </div>
                }
            />
        </div>
    );
};

export default Educations;
