import React from 'react';
import { useEffect } from 'react';
import NavBar from "../components/common/navBar";
import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import INFO from "../data/user";
import "./styles/resume.css";


const Resume = () => {
    useEffect(() => {
        document.title = `Resume | ${INFO.main.title}`;
    }, []);
    return (
        <React.Fragment>
            <div className="page-content">
                <NavBar active="resume" />
                <div className="content-wrapper">
                    <div className="resume-logo-container">
                        <div className="resume-logo">
                            <Logo width={46} />
                        </div>
                    </div>
                    <div className="resume-container">
                        <iframe
                            title="Resume"
                            src={`${process.env.PUBLIC_URL}/Yasir_Yilmazcoban_Resume.pdf`}
                            width="100%"
                            height={window.innerHeight}
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="page-footer">
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default Resume;
