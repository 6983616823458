const INFO = {
	main: {
		title: "Yasir Yilmazcoban",
		name: "Yasir Y.",
		email: "yasir@yilmazcoban.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/yasirylmzcbn",
		linkedin: "https://www.linkedin.com/in/yasir-yilmazcoban",
		instagram: "https://www.instagram.com/yasir_ylmzcbn/",
	},

	homepage: {
		title: "Hi there, I'm Yasir!",
		description:
			"Welcome to my portfolio website! Feel free to look around to learn more about me and my projects. You can also connect with me on LinkedIn or send me an email. I'm still looking for SWE internship opportunities for Summer 2025!",
	},

	about: {
		title: "About me",
		description:
			"Hi there! My name is Yasir Yilmazcoban. I'm a Turkish-American junior computer science student at Texas A&M University. I'm interested in software development and engineering. I'm currently working on a roommate companion app called Sp/it and continuing my summer internship at R1649 remotely. I'll also be interning as a SWE at Meta this summer! I'm looking for SWE Internships for Summer 2025!\nI have experience working with Python, C++, Java, JavaScript, SQL, React/React Native, Express.js, Firebase, Cloud Databases, and more (my full skill list is on my resume). I'm also learning project management through my role in Sp/it, where I use Jira to plan and assign sprints to promote agile development. I'm also learning about cloud computing, web development, and blockchain development through my internship at R1649. I'm a quick learner and am always looking to learn new skills and technologies!\nI'm based in Texas but I'm open to relocation and remote work. I hold permanent residency status in the U.S. and am authorized to work in the U.S. without sponsorship. Feel free to reach out to me if you have any questions or opportunities!",
	},

	projects: [
		{
			title: "Sp/it",
			description:
				"A roommate companion app that helps you split chores and expenses to keep track of who owes who. I'm the project manager for this project and am leading a team of 15 developers. Built with React Native, Expo, and Firebase.",
			logo: ["https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/539px-React-icon.svg.png", "https://static.expo.dev/static/brand/square-512x512.png", "https://cdn.dribbble.com/users/528264/screenshots/3140440/firebase_logo_1x.png"],
			linkText: "View Early Demo",
			link: "https://youtu.be/cI3W8Aa5N38",
		},

		{
			title: "Image Generating Apparatus",
			description:
				"Hackathon project that creates images as you read your favorite e-book! Built with Python, Flask, JS, and OpenAI's APIs for ChatGPT and DALL-E 2.",
			logo: ["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png", "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png", "https://cdn.icon-icons.com/icons2/2389/PNG/512/flask_logo_icon_145276.png"],
			linkText: "View Project",
			link: "https://github.com/yasirylmzcbn/IGA",
		},

		{
			title: "Seam Carving",
			description:
				"School project that uses the seam carving algorithm to resize images without distorting the main subject of the image based on the energies of vertical seams in comparison to the seams around it. Built with C++.",
			logo: ["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/cpp/cpp.png"],
			linkText: "View Project",
			link: "https://github.com/yasirylmzcbn/seam-carving"
		},

		{
			title: "Microsoft Rewards Bot",
			description:
				"Automated bot that searches Bing for you and earns you Microsoft Rewards points which can be redeemed for gift cards. Built with Python and Selenium.",
			logo: ["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png", "https://www.logotypes101.com/logos/280/9CAA74982DF68DB2243DF3CE95F2D60C/selenium-logo.png"],
			linkText: "View Project",
			link: "https://github.com/yasirylmzcbn/MSRewardsBot",
		},

		{
			title: "Credit Card Number Validator",
			description:
				"Simple credit card number validator that uses the Luhn algorithm to check if a credit card number is valid and for which company it belongs to. Built with Python.",
			logo: ["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png"],
			linkText: "View Project",
			link: "https://github.com/yasirylmzcbn/credit-card-number-validator",
		},

		{
			title: "Powergrid",
			description:
				"Graphical implementation of the board game Powergrid. Uses Djikstra's algorithm to find the shortest path between the cities on the board. Built with Java.",
			logo: ["https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png"],
			linkText: "View Project",
			link: "https://github.com/mbugti04/powergrid"
		}
	],
};

export default INFO;
