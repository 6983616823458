import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
							Things I do in my free time and for school projects
						</div>

						<div className="subtitle projects-subtitle">
							I've been trying to learn new technologies by doing 
							projects since middle school. I've dabbled with 
							object-oriented programming, web development, mobile
							development, game design, cloud databases, and more.
							Here are some of my favorite projects I've worked on.
							If you'd like to see some of the other projects I've
							worked on, feel free to check out 
							my <a href="https://github.com/yasirylmzcbn?tab=repositories">GitHub Repos</a> or
							reach out to me!
						</div>

						<div className="projects-list">
							<AllProjects />
						</div>
					</div>
				</div>
			</div>
			<div className="page-footer">
				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Projects;
