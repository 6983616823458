import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";
import "./styles/works.css";

const Works = () => {
	const workExperiences = [
		{
			company: "Meta",
			position: "Software Engineering Intern",
			duration: "May 2024 - August 2024",
			logoSrc: "./meta_logo.png",
		},
		{
			company: "Sp/it",
			position: "Project Manager",
			duration: "September 2023 - Present",
			logoSrc: "./split_logo_small.png",
		},
		{
			company: "Texas A&M University",
			position: "Peer Teacher",
			duration: "August 2023 - December 2023",
			logoSrc: "./tamu_logo.png",
		},
		{
			company: "R1649",
			position: "Software Engineering Intern",
			duration: "June 2023 - Present",
			logoSrc: "./r1649_logo.jpg",
		},
	];

	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						{workExperiences.map((experience, index) => (
							<div className="work" key={index}>
								<img
									src={experience.logoSrc}
									alt={experience.company}
									className="work-image"
								/>
								<div className="work-title">{experience.company}</div>
								<div className="work-secondary">
									<div className="work-subtitle">{experience.position}</div>
									<div className="work-duration">{experience.duration}</div>
								</div>
							</div>
						))}
					</div>
				}
			/>
		</div>
	);
};

export default Works;
